import {
  type ProfilePhotoContentType,
  Alert,
  AlertVariant,
  ProfilePhoto,
} from '@shared/ui-components';
import { PICTURE_UPLOAD_ERROR_ID } from '@shared/utils';

import styles from './profile-photo-dialog-content.module.scss';

const PARENT_CLASSNAME = 'profile-photo-dialog-content';

export type ProfilePhotoDialogContentType = {
  modalDescription: string;
  pictureFileInputHint: string;
  upload: string;
};

type ProfilePhotoDialogContentProps = {
  cmsContent: ProfilePhotoDialogContentType & ProfilePhotoContentType;
  imageSrc: string | undefined;
  profileUploadError?: string | undefined;
};

export const ProfilePhotoDialogContent = ({
  cmsContent,
  imageSrc,
  profileUploadError,
}: ProfilePhotoDialogContentProps) => {
  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <p
        className={styles[`${PARENT_CLASSNAME}__paragraph`]}
        data-testid="dialog-content-description"
      >
        {cmsContent.modalDescription}
      </p>
      <p
        aria-hidden="true"
        className={styles[`${PARENT_CLASSNAME}__picture-requirements`]}
        id="profile-picture-requirements"
      >
        {cmsContent.pictureFileInputHint}
      </p>

      {profileUploadError && (
        <Alert
          className={styles[`${PARENT_CLASSNAME}__error-message`]}
          id={PICTURE_UPLOAD_ERROR_ID}
          message={profileUploadError}
          variant={AlertVariant.Error}
        />
      )}

      <div
        className={styles[`${PARENT_CLASSNAME}__photo-container`]}
        data-testid="dialog-content-photo-container"
      >
        <div className={styles[`${PARENT_CLASSNAME}__photo`]} data-testid="dialog-content-photo">
          <ProfilePhoto
            actionLabelText={cmsContent.upload}
            cmsContent={{
              pictureDescription: cmsContent.pictureDescription,
            }}
            hasBorder={false}
            imageSrc={imageSrc}
            isFluid
          />
        </div>
      </div>
    </div>
  );
};
