'use client';

import classnames from 'classnames';
import { useLocale } from 'next-intl';

import { Badge, BadgeVariant, LockIcon, RenderHTML } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { CardLink } from '../../../../../components';
import { DEFAULT_LOCALE } from '../../../../../i18n/locales';
import { DevelopedFeatures } from '../../../../../utils/feature-flags/constants';
import { useFeatureFlags } from '../../../../../utils/feature-flags/use-feature-flags';
import { convertSecondsToMinutes } from '../../../../../utils/helpers/convert-seconds-to-minutes';
import { getCardLinkDescription } from '../../../../../utils/helpers/get-card-link-description';
import { getContentFallbackImageSource } from '../../../../../utils/helpers/get-content-fallback-image-source';
import { getContentTypeIconComponent } from '../../../../../utils/helpers/get-content-type-icon-component';
import { getFeaturedPeopleOrAuthors } from '../../../../../utils/helpers/get-featured-people-or-authors';
import { getTextFromHtmlString } from '../../../../../utils/helpers/get-text-from-html-string';
import { type LearnCarouselCardData } from '../../../actions/types';
import { type LearnGenericTextsCmsContent } from '../../../graphql/get-learn-generic-texts-cms-content';

import { LearnContentCarouselCardImage } from './learn-content-carousel-card-image-with-number';
import styles from './learn-content-carousel-card.module.scss';

export type LearnContentCarouselCardProps = {
  card: LearnCarouselCardData;
  carouselTitle: string;
  cmsContent: LearnGenericTextsCmsContent;
  selectedFilterValue?: string | null;
  svgNumber?: number | undefined;
};

export const LearnContentCarouselCard = ({
  card,
  carouselTitle,
  cmsContent,
  selectedFilterValue,
  svgNumber,
}: LearnContentCarouselCardProps) => {
  const ContentTypeIconComponent = getContentTypeIconComponent(card?.ContentTypeSelection);
  const fallbackImageSource = getContentFallbackImageSource(card?.ContentTypeSelection);
  const imageAlt = card?.ThumbnailImage?.AltText ?? card?.Heading ?? '';
  const people = getFeaturedPeopleOrAuthors(card);
  const firstAuthor = people[0];
  const otherAuthorsCount = firstAuthor && people.length > 1 ? people.length - 1 : undefined;
  const description = getCardLinkDescription(card?.SubheadingText, card?.Subheading);
  const locale = useLocale();
  const formattedUrl =
    locale === DEFAULT_LOCALE
      ? card?.RelativePath
      : card?.RelativePath?.replace(/^\/[\w-]+\//u, '/');
  const formattedReadTimeLabel =
    card.ReadTimeInSeconds && card.ReadTimeInSeconds > 0
      ? formatTranslationStringOnClient(cmsContent.minutesLabel, {
          readTime: convertSecondsToMinutes(card.ReadTimeInSeconds),
        })
      : null;

  // todo remove this flag once feature is completed
  const { getIsFeatureEnabled } = useFeatureFlags();
  const isLockedContentFeatureEnable = getIsFeatureEnabled(DevelopedFeatures.LockedResources);

  const isLocked = isLockedContentFeatureEnable ? card.membersOnlyContent : undefined;

  return (
    <CardLink
      ImageComponent={
        <LearnContentCarouselCardImage
          alt={imageAlt}
          callToActionText={cmsContent.callToSignInLabel}
          fallbackSrc={fallbackImageSource}
          isLocked={isLocked}
          numberTranslation={cmsContent.number}
          src={card?.ThumbnailImage?.ImageReference?.Url ?? fallbackImageSource}
          svgNumber={svgNumber}
        />
      }
      actionClassName={classnames(
        styles['learn-content-carousel-card__action'],
        'card-with-hover-effect'
      )}
      className={classnames(styles['learn-content-carousel-card'])}
      data-ga-carousel-filter={selectedFilterValue}
      data-ga-carousel-header={carouselTitle}
      scroll
      to={isLocked ? '#' : formattedUrl ?? undefined}
    >
      <div className={styles['learn-content-carousel-card__type-badge-container']}>
        <Badge>
          <ContentTypeIconComponent className={styles['learn-content-carousel-card__type-icon']} />
        </Badge>
        {isLocked && (
          <Badge variant={BadgeVariant.Tertiary}>
            <LockIcon className={styles['learn-content-carousel-card__type-icon']} />
            <span className={styles['learn-content-carousel-card__type-badge-text']}>
              {cmsContent.membersOnlyLabel}
            </span>
          </Badge>
        )}
      </div>
      {/* TODO: implementation in future */}
      {/* <BookmarkIcon className={styles['learn-content-carousel-card__bookmark-icon']} /> */}
      <div className={styles['learn-content-carousel-card__content']}>
        {card?.Heading && (
          <h3 className={styles['learn-content-carousel-card__title']} title={card.Heading}>
            {card.Heading}
          </h3>
        )}
        <div className={styles['learn-content-carousel-card__info']}>
          <p className={styles['learn-content-carousel-card__authors']} title={people.join('; ')}>
            <span className={styles['learn-content-carousel-card__first-author']}>
              {firstAuthor}
            </span>
            {otherAuthorsCount && (
              <span className={styles['learn-content-carousel-card__author-count']}>
                +{otherAuthorsCount}
              </span>
            )}
          </p>
          {formattedReadTimeLabel && (
            <span
              className={classnames(styles['learn-content-carousel-card__read-time'], {
                [styles['learn-content-carousel-card__read-time--with-separator']]: firstAuthor,
              })}
              title={formattedReadTimeLabel}
            >
              {formattedReadTimeLabel}
            </span>
          )}
        </div>
        <RenderHTML
          className={styles['learn-content-carousel-card__description']}
          html={description}
          title={getTextFromHtmlString(description)}
        />
      </div>
    </CardLink>
  );
};
