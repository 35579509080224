import classnames from 'classnames';

import { type ImageWithFallbackProps, LockIcon } from '@shared/ui-components';

import { ImageWrapper } from '../../../../../../components';

import { SvgNumber01 } from './number-01';
import { SvgNumber02 } from './number-02';
import { SvgNumber03 } from './number-03';
import { SvgNumber04 } from './number-04';
import { SvgNumber05 } from './number-05';
import { SvgNumber06 } from './number-06';
import { SvgNumber07 } from './number-07';
import { SvgNumber08 } from './number-08';
import { SvgNumber09 } from './number-09';
import { SvgNumber10 } from './number-10';
import styles from './learn-content-carousel-card-image.module.scss';

export type LearnContentCarouselCardImageProps = {
  callToActionText: string;
  isLocked?: boolean;
  numberTranslation: string;
  svgNumber: number | undefined;
} & ImageWithFallbackProps;

const numberSvgs: {
  [key: number]: JSX.Element;
} = {
  1: <SvgNumber01 />,
  2: <SvgNumber02 />,
  3: <SvgNumber03 />,
  4: <SvgNumber04 />,
  5: <SvgNumber05 />,
  6: <SvgNumber06 />,
  7: <SvgNumber07 />,
  8: <SvgNumber08 />,
  9: <SvgNumber09 />,
  10: <SvgNumber10 />,
};

export const LearnContentCarouselCardImage = ({
  alt,
  callToActionText,
  fallbackSrc,
  height,
  isLocked = false,
  numberTranslation,
  src,
  svgNumber,
  width,
}: LearnContentCarouselCardImageProps) => {
  return (
    <div
      className={classnames(styles['image-container'], {
        [styles['image-container--is-locked']]: isLocked,
      })}
    >
      {svgNumber && (
        <div
          aria-label={`${numberTranslation} ${svgNumber.toString()}`}
          className={styles['number']}
          role="img"
        >
          {numberSvgs[svgNumber]}
        </div>
      )}
      {isLocked && (
        <>
          <LockIcon className={styles['image-container__icon']} />
          <span className={styles['image-container__call-to-action']}>{callToActionText}</span>
        </>
      )}
      <ImageWrapper
        alt={alt}
        className={classnames(styles['image'], { [styles['image--is-locked']]: isLocked })}
        fallbackSrc={fallbackSrc}
        height={height}
        src={src}
        width={width}
      />
    </div>
  );
};
